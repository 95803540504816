import React, { useState, useRef, useEffect, useCallback } from 'react'
import SourcesListItem from './sourcesListItem';
import useInfiniteSearch from '../utils/useInfiniteSearch';
import styled from 'styled-components'

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
`;

export default function SourcesInfiniteList(props) {
  const [query, setQuery] = useState({})
  const [pageNumber, setPageNumber] = useState(1)

  useEffect(() => {
    function handleSearch(filters) {
      setQuery({ media: filters.join(',') })
      setPageNumber(1)
    }

    props.filterQuery.current = handleSearch
  }, []);

  const {
    datas,
    hasMore,
    loading,
    error
  } = useInfiniteSearch(`${process.env.REACT_APP_API_BASE_URL}/api/sources`, query, pageNumber)

  const observer = useRef()

  const lastItemElementRef = useCallback(node => {
    if (loading) return
    if (observer.current) observer.current.disconnect()

    observer.current = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting && hasMore) {
        setPageNumber(prevPageNumber => prevPageNumber + 1)
      }
    })
    if (node) observer.current.observe(node)
  }, [loading, hasMore])

  return (
    <>
      <Container>
        {datas.map((item, index) => {
          if (datas.length > 15 && index === datas.length - 10) {
            return <><SourcesListItem key={item.Id} name={item.Name} media={item.Media} origin={item.Origin} link={`/sources/${item.Id}`} /><div ref={lastItemElementRef}></div></>
          } else {
            return <SourcesListItem key={item.Id} name={item.Name} media={item.Media} origin={item.Origin} link={`/sources/${item.Id}`} />
          }
        })}
        <div>{loading && 'Loading...'}</div>
        <div>{error && 'Error'}</div>
      </Container>
    </>
  )
}