import styled from 'styled-components'
import { useState, useEffect } from 'react';
// import FilterCheckBox from '../filterCheckBox';
import CheckboxTree from "react-checkbox-tree";
import "react-checkbox-tree/lib/react-checkbox-tree.css";

import {
  MdCheckBox,
  MdCheckBoxOutlineBlank,
  MdChevronRight,
  MdKeyboardArrowDown,
  MdAddBox,
  MdIndeterminateCheckBox,
  MdFolder,
  MdFolderOpen,
  MdInsertDriveFile
} from "react-icons/md";


function FilterListGroups(props) {

  const [checked, setChecked] = useState([]);
  const [expanded, setExpanded] = useState([]);

  function updateQuery(filters, nodes) {

    let filterList = { aircraft: [], group: [], letter: [] };

    if (filters && filters.length > 0) {
      nodes.forEach(node => {
        let label = node.label;
        node.children.forEach(child => {
          if (filters.includes(child.value)) {
            filterList[label].push(child.value);
          }
        })
      })
    }

    setChecked(filters);
    props.isFiltered(filterList);
  }

  const Container = styled.div`
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
    `;

  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [hasError, setHasError] = useState(false);
  let url = `${process.env.REACT_APP_API_BASE_URL}/api/filters/groups`;

  if (window.location.search) {
    url = url + window.location.search;
  }

  useEffect(() => {
    setIsLoading(true);
    fetch(url)
      .then((res) => res.json())
      .then((data) => {
        setData(data);
      })
      .catch((err) => {
        setHasError(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [url]);

  if (isLoading) {
    return <h1>Loading...</h1>;
  }

  if (hasError) {
    return <p>Something went wrong!!!</p>;
  }

  const aircraftArray = data.aircraft.filter(a => !!a).map(a => ({ value: a, label: a }));
  const groupsArray = data.groups.filter(g => !!g).map(g => ({ value: g, label: g }));
  const lettersArray = data.letters.filter(l => !!l).map(l => ({ value: l, label: l }));

  const nodes = [
    {
      value: data.aircraft,
      label: "aircraft",
      children: aircraftArray,
      className: "aircraft"
    },
    {
      value: data.groups,
      label: "group",
      children: groupsArray,
      className: "group"
    },
    {
      value: data.letters,
      label: "letter",
      children: lettersArray,
      className: "letter"
    },
  ];

  const icons = {
    check: <MdCheckBox className="rct-icon rct-icon-check" />,
    uncheck: <MdCheckBoxOutlineBlank className="rct-icon rct-icon-uncheck" />,
    halfCheck: (
      <MdIndeterminateCheckBox className="rct-icon rct-icon-half-check" />
    ),
    expandClose: (
      <MdChevronRight className="rct-icon rct-icon-expand-close" />
    ),
    expandOpen: (
      <MdKeyboardArrowDown className="rct-icon rct-icon-expand-open" />
    ),
    expandAll: <MdAddBox className="rct-icon rct-icon-expand-all" />,
    collapseAll: (
      <MdIndeterminateCheckBox className="rct-icon rct-icon-collapse-all" />
    ),
    parentClose: <MdFolder className="rct-icon rct-icon-parent-close" />,
    parentOpen: <MdFolderOpen className="rct-icon rct-icon-parent-open" />,
    leaf: <MdInsertDriveFile className="rct-icon rct-icon-leaf-close" />
  };



  return (
    <Container className='category-list'>

      <CheckboxTree
        nodes={nodes}
        checked={checked}
        expanded={expanded}
        onCheck={checked => updateQuery(checked, nodes)}
        onExpand={expanded => setExpanded(expanded)}
        icons={icons}
      />

      {/* <h3>Aircraft</h3>
            {(data.aircraft).map((ac, index) => (
                <FilterCheckBox key={index} filterClass="aircraft" onChange={updateQuery} opt={ac} />
            ))}
            <h3>Groups</h3>
            {(data.groups).map((group, index) => (
                <FilterCheckBox key={index} filterClass="groups" onChange={updateQuery} opt={group} />
            ))}
            <h3>Letters</h3>
            {(data.letters).map((letter, index) => (
                <FilterCheckBox key={index} filterClass="letters" onChange={updateQuery} opt={letter} />
            ))} */}
    </Container>
  );
}

export default FilterListGroups;