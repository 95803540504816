import styled from 'styled-components';
import { useState, useEffect } from 'react';
import React from "react";
import { useLocation } from "react-router-dom";
import MissionsListItem from '../../missions/missionsListItem';
import TargetsListItem from '../../targets/targetsListItem';
import BasesListItem from '../../bases/basesListItem';
import PhotosListItem from '../../photos/photosListItem';

function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

function SearchContent(props) {

  let query = useQuery();

  const Container = styled.div`
    width: 100%;
    min-height: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  `;

  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [hasError, setHasError] = useState(false);
  let url = `${process.env.REACT_APP_API_BASE_URL}/api/search?q=${query.get("q")}`;

  useEffect(() => {
    setIsLoading(true);
    fetch(url)
      .then((res) => res.json())
      .then((data) => {
        setData(data);
      })
      .catch((err) => {
        setHasError(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [url]);

  if (isLoading) {
    return <h1>Loading...</h1>;
  }

  if (hasError || !data) {
    return <p>Something went wrong!!!</p>;
  }

  return (
    <Container>
      <div style={{ marginTop: 80 }}></div>
      {!!data.Missions && <h3>Missions</h3>}
      {data.Missions && (data.Missions).map(miss => (
        <MissionsListItem key={miss.Id}
          name={miss.Name}
          descr={(miss.Description).substring(0, 25) + "..."}
          date={miss.Date}
          link={`/missions/${miss.Id}`} />
      ))}
      {!!data.Targets && <h3>Targets</h3>}
      {data.Targets && (data.Targets).map(tar => (
        <TargetsListItem key={tar.Id}
          name={nameCheck(tar.Name)}
          location={locationBuilder(tar.City, tar.Country)}
          types={typeBuilder(tar.TargetType, tar.SubType)}
          link={`/targets/${tar.Id}`} />
      ))}
      {!!data.Bases && <h3>Bases</h3>}
      {data.Bases && (data.Bases).map(base => (
        <BasesListItem key={base.Id} name={base.Name} location={locationBuilder(base.County, base.Country)} link={`/bases/${base.Id}`} />
      ))}
      {!!data.Photos && <h3>Photos</h3>}
      {data.Photos && (data.Photos).map(photo => (
        <PhotosListItem key={photo.Id} descr={photo.Description} source={photo.Source_Site} country={photo.Country} link={`/photos/${photo.Id}`} />
      ))}
    </Container>
  );
}

export default SearchContent;

function locationBuilder(city, country) {
  let local = ""
  if (city) {
    local = city;
  }
  if (country) {
    if (local !== "") {
      local = `${local}, `;
    }
    local = local + country;
  }
  if (local === "") {
    local = 'Unknown Location';
  }
  return local;
}

function typeBuilder(targetType, subType) {
  let t = ""
  if (targetType) {
    t = targetType;
  }
  if (subType) {
    if (t !== "") {
      t = `${t}/`;
    }
    t = t + subType;
  }
  if (t === "") {
    t = 'Unknown Location';
  }
  return t;
}

function nameCheck(name) {
  if (name && name !== "") {
    return name;
  }
  return 'Unknown Target Name';
}