import styled from 'styled-components'
import { useState, useEffect } from 'react';
import HomeMissionListItem from './homeMissionsListItem'

function HomeMissionList(props) {

  const Container = styled.div`
    width: 100%;
  `;

  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      setHasError(false);
      try {
        const res = await fetch(
          `${process.env.REACT_APP_API_BASE_URL}/api/missions-today`,
        );
        const json = await res.json();
        setData(json);
      } catch (error) {
        setHasError(true);
      }
      setIsLoading(false);
    };
    fetchData();
  }, [setData]);

  return (
    <Container>
    {hasError && <p>Something went wrong.</p>}
      {isLoading ? (
        <h1>Loading ...</h1>
      ) : (
        <div>
          {data.map(item => (
              <HomeMissionListItem key={item.Id} name={item.Name} descr={item.Description} date={item.Date} bg="#22333B" link={`/missions/${item.Id}`}/>
          ))}
        </div>
      )}
    </Container>
  );
}

export default HomeMissionList;
