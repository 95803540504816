import styled from 'styled-components'
import {Link} from 'react-router-dom'

function GroupsListItem(props) {

  const Container = styled.div`
    width: 93%;
    min-height: 70px;
    margin: 20px auto;
    background: #22333B;
  `;

  const P = styled.p`
    color: #FFF;
    margin: 0;
    width: 100%;
    text-align: center;
    line-height:28px;
      b{
        font-weight:500;
      }
  `;
  
  const Div = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    background: transparent;
    justify-content: center;
    min-height: 70px;
    position: relative;
    opacity: 90%;
    padding: 10px;
    @media (max-width: 1024px) {
      flex-direction: column;
    }
  `;

  return (
    <Container>
      <Link to={props.link}>
        <Div>
            <P><b>Name:</b> {props.name}</P>
            <P><b>Type:</b> {props.groupType}</P>
        </Div>
      </Link>
    </Container>
  );
}

export default GroupsListItem;