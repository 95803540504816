import styled from 'styled-components'
import { useState, useEffect, useCallback } from 'react';
import AsyncSelect from 'react-select/async'
import CheckboxTree from "react-checkbox-tree";
import "react-checkbox-tree/lib/react-checkbox-tree.css";
import {
  MdCheckBox,
  MdCheckBoxOutlineBlank,
  MdChevronRight,
  MdKeyboardArrowDown,
  MdAddBox,
  MdIndeterminateCheckBox,
  MdFolder,
  MdFolderOpen,
  MdInsertDriveFile
} from "react-icons/md";

const Container = styled.div`
display: flex;
flex-direction: column;
align-items: flex-start;
justify-content: center;      
`;

const parseLocation = ({ City, Country }) => {
  if (!City && Country) return Country
  if (City && !Country) return City
  return `${City}, ${Country}`
}

function FilterListTargets({isFiltered}) {
  const [checked, setChecked] = useState([]);
  const [expanded, setExpanded] = useState([]);
  const [filters, setFilters] = useState({ Location: [], "Target Type": [] })

  const handleSuggestionFetch = useCallback((val) => {
    return fetch(`${process.env.REACT_APP_API_BASE_URL}/api/target/location?search=${encodeURI(val.trim())}`)
      .then(res => res.json())
      .then(j => j.Data)
  }, [])

  function updateQuery(f, nodes) {
    console.log('update', f, nodes)
    setChecked(f);
    setFilters({ ...filters, "Target Type": f })
  }

  useEffect(() => {
    if (!isFiltered) return
    isFiltered(filters)
  }, [filters, isFiltered])

  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [hasError, setHasError] = useState(false);

  let url = `${process.env.REACT_APP_API_BASE_URL}/api/filters/target`;

  if (window.location.search) {
    url = url + window.location.search;
  }

  useEffect(() => {
    setIsLoading(true);
    fetch(url)
      .then((res) => res.json())
      .then((data) => {
        setData(data);
      })
      .catch((err) => {
        setHasError(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [url]);

  if (isLoading) {
    return <h1>Loading...</h1>;
  }

  if (hasError) {
    return <p>Something went wrong!!!</p>;
  }

  const TargetTypeArray = data.TargetType.filter(i => !!i).map(i => ({ value: i, label: i }));

  const nodes = [
    {
      value: data.TargetType,
      label: "Target Type",
      children: TargetTypeArray,
      className: "TargetType"
    },
  ];

  const icons = {
    check: <MdCheckBox className="rct-icon rct-icon-check" />,
    uncheck: <MdCheckBoxOutlineBlank className="rct-icon rct-icon-uncheck" />,
    halfCheck: (
      <MdIndeterminateCheckBox className="rct-icon rct-icon-half-check" />
    ),
    expandClose: (
      <MdChevronRight className="rct-icon rct-icon-expand-close" />
    ),
    expandOpen: (
      <MdKeyboardArrowDown className="rct-icon rct-icon-expand-open" />
    ),
    expandAll: <MdAddBox className="rct-icon rct-icon-expand-all" />,
    collapseAll: (
      <MdIndeterminateCheckBox className="rct-icon rct-icon-collapse-all" />
    ),
    parentClose: <MdFolder className="rct-icon rct-icon-parent-close" />,
    parentOpen: <MdFolderOpen className="rct-icon rct-icon-parent-open" />,
    leaf: <MdInsertDriveFile className="rct-icon rct-icon-leaf-close" />
  };

  return (
    <Container className='category-list'>
      <CheckboxTree
        nodes={nodes}
        checked={checked}
        expanded={expanded}
        onCheck={checked => updateQuery(checked, nodes)}
        onExpand={expanded => setExpanded(expanded)}
        icons={icons}
      />

      <div style={{ width: '100%' }}>
        <AsyncSelect
          isMulti
          getOptionLabel={parseLocation}
          getOptionValue={JSON.stringify}
          placeholder='Locations'
          loadOptions={handleSuggestionFetch}
          onChange={val => setFilters({ ...filters, Location: val })}
        ></AsyncSelect>
      </div>
    </Container>
  );
}

export default FilterListTargets;
