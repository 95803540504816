import styled from 'styled-components'
import {Link} from 'react-router-dom'

function TargetsListItem(props) {

  const Container = styled.div`
    width: 93%;
    min-height: 70px;
    margin: 20px auto;
    background: #22333B;
  `;

  const P = styled.p`
    color: #FFF;
    margin: 0;
    width: 100%;
    line-height: 28px;
    text-align: center;
    b{
      font-weight:500;
    }
  `;
  
  const Div = styled.div`
    display: flex;
    align-items: center;
    background: transparent;
    justify-content: center;
    min-height: 70px;
    position: relative;
    opacity: 90%;
    padding: 12px;
    @media (max-width: 1024px) {
      flex-direction: column;
    }
  `;

  return (
    <Container>
      <Link to={props.link}>
        <Div>
            <P><b>Type:</b> {props.types}</P>
            <P><b>Name:</b> {props.name}</P>
            <P><b>Location:</b> {props.location}</P>
        </Div>
      </Link>
    </Container>
  );
}

export default TargetsListItem;