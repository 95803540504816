import HomeSectionCard from './homeLink';
import styled from 'styled-components';
import HomeMissionList from './homeMissionList';
import HorizontalRule from '../utils/HorizontalRule';

function Home() {

  const HomeCardRow = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
      @media (max-width: 1024px) {
        flex-wrap:wrap;
      }
  `;


  const SpacedContainer = styled.div`
    margin-top: 15px;
  `;

  return (
    <div className='main'>
      <HomeCardRow>
        <HomeSectionCard link="/groups" title="Groups" tagline="Training and Operational histories of the Bomber, Fighter, Recon and Transport groups and squadrons" bg="#22333B" img={`${process.env.REACT_APP_API_BASE_URL}/img/site/poster.png`} />
        <HomeSectionCard link="/bases" title="Bases" tagline="Airfields used by the Eighth Air Force for training and operations in the US and UK" bg="#605E5B" img={`${process.env.REACT_APP_API_BASE_URL}/img/site/air-tower.jpg`} />
        <HomeSectionCard link="/targets" title="Targets" tagline="Missions, maps, dates and photographs of targets of the Eighth Air Force" bg="#22333B" img={`${process.env.REACT_APP_API_BASE_URL}/img/site/target.jpg`} />
        <HomeSectionCard link="/missions" title="Missions" tagline="View Eighth Air Force missions for a specific date" bg="#605E5B" img={`${process.env.REACT_APP_API_BASE_URL}/img/site/mission.jpg`} />
      </HomeCardRow>

      <HomeCardRow>
        <HomeSectionCard link="/aircraft" title="Aircraft" tagline="Learn more about the various planes used" bg="#605E5B" img={`${process.env.REACT_APP_API_BASE_URL}/img/site/aircraft.jpg`} />
        <HomeSectionCard link="/photos" title="Photos" tagline="See the photos that have been collected" bg="#22333B" img={`${process.env.REACT_APP_API_BASE_URL}/img/site/cropped_8thafhf_logo.png`} />
        <HomeSectionCard link="/charts" title="Charts" tagline="Collection of charts to help aid in various informational explanations" bg="#605E5B" img={`${process.env.REACT_APP_API_BASE_URL}/img/site/map.jpg`} />
        <HomeSectionCard link="/sources" title="Sources" tagline="The sources that have helped build this collection of information" bg="#22333B" img={`${process.env.REACT_APP_API_BASE_URL}/img/site/42-94786.jpg`} />
      </HomeCardRow>

      <HomeCardRow>
        <HomeSectionCard link="/honor_roll" title="Honor Roll" tagline="See the list of the fallen" bg="#605E5B" img={`${process.env.REACT_APP_API_BASE_URL}/img/site/aircraft.jpg`} />
      </HomeCardRow>

      <SpacedContainer>
        <HorizontalRule text="Today in History" />
        <HomeMissionList />
      </SpacedContainer>
    </div>
  );
}

export default Home;
