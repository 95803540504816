import styled from 'styled-components';
import Navigation from '../navigation/navigation';
import A404Message from './a404Message'

function A404Page() {

    const Container = styled.div`
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 4fr;
    grid-template-rows: 1fr;
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    @media (max-width:1024px){
      grid-template-columns: 1fr 2fr;
    }
  `;

  return (
    <Container>
        <Navigation />
        <A404Message />
    </Container>
  );
}

export default A404Page;