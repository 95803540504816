
import React, { useEffect, useState, useRef, useCallback } from 'react'
import BasesListItem from './basesListItem';
import useInfiniteSearch from '../utils/useInfiniteSearch';
import styled from 'styled-components'

export default function BasesInfiniteList(props) {

    const Container = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
  `;

  const [query, setQuery] = useState({})
  const [pageNumber, setPageNumber] = useState(1)

  useEffect(() => {
    props.filterQuery.current = handleSearch
  }, []);
  
  const {
    datas,
    hasMore,
    loading,
    error
  } = useInfiniteSearch(`${process.env.REACT_APP_API_BASE_URL}/api/bases`, query, pageNumber)

  const observer = useRef()
  
  const lastItemElementRef = useCallback(node => {
    if (loading) return
    if (observer.current) observer.current.disconnect()
  
    observer.current = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting && hasMore) {
        setPageNumber(prevPageNumber => prevPageNumber + 1)
      }
    })
    if (node) observer.current.observe(node)
  }, [loading, hasMore])

  function handleSearch(filters) {
    setQuery({countries: filters.countries.join(','), counties: filters.counties.join(',')})
    setPageNumber(1)
  }

  function checkString(stringData) {
    if (stringData && stringData != "") {
      return stringData;
    }

    return "N/A";
  }

  return (
    <>
        <Container>
            {datas.map((item, index) => {
              if (datas.length > 15 && index === datas.length - 10) {
                return <><BasesListItem key={item.Id} name={checkString(item.Name)} location={locationBuilder(item.County, item.Country)} link={`/bases/${item.Id}`}/><div ref={lastItemElementRef}></div></>
              } else {
                return <BasesListItem key={item.Id} name={checkString(item.Name)} location={locationBuilder(item.County, item.Country)} link={`/bases/${item.Id}`}/>
              }
            })}
            <div>{loading && 'Loading...'}</div>
            <div>{error && 'Error'}</div>
        </Container>
    </>
  )
}

function locationBuilder(city, country) {
  let local = ""
  if (city) {
      local = city;
  }
  if (country) {
      if (local !== "") {
          local = `${local}, `;
      }
      local = local + country;
  }
  if (local === "") {
      local = 'Unknown Location';
  }
  return local;
}