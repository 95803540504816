import styled from 'styled-components';
import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Navigation from '../navigation/navigation';
import BackButton from '../utils/BackButton';
import Segment from '../utils/segments';
import GroupReference from '../utils/groupReference';

const Container = styled.div`
width: 100%;
display: grid;
grid-template-columns: 1fr;
grid-template-rows: 56px 1fr;
grid-column-gap: 0px;
grid-row-gap: 0px;
margin-top: 80px;
@media (max-width:600px){
  margin-top:136px;
}
`;
const Div = styled.div`
width: 100%;
margin-top: 52px;
  p{
    color: #22333B;
    font-family: 'Rubik',sans-serif;
    font-weight: 400;
    text-align:center;
    padding:0 10px;
    @media (max-width:600px){
      font-size:14px;
    }
  }
  b{
    font-size:20px;     
  }
`;
const P = styled.div`
width: 70%;
max-width: 1200px;
min-height: 100px;
color: #22333B;
font-family: 'Rubik',sans-serif;
font-weight: 400;
margin: 0 auto;
text-align: center;
font-size:18px;    

`;

function SingleMissionsContent(props) {
  const { id } = useParams();

  console.log('mission props', props)

  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const url = `${process.env.REACT_APP_API_BASE_URL}/api/missions/${id}`;

  useEffect(() => {
    setIsLoading(true);
    fetch(url)
      .then((res) => res.json())
      .then((data) => {
        setData(data);
      })
      .catch((err) => {
        setHasError(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [url]);

  if (isLoading) {
    return <h1>Loading...</h1>;
  }

  if (hasError || !data) {
    return <p>Something went wrong!!!</p>;
  }

  function checkString(stringData) {
    if (stringData && stringData != "") {
      return stringData;
    }

    return "N/A";
  }

  let count = 0;

  const counter = () => {
      count++;
      return count;
  }

  console.log('data', data)

  return (
    <Container>
      <Navigation />
      <Div>
        <BackButton link={props.location.from || "/missions"} />
        <div className='description-container'>
          <h1>Name: {checkString(data.Name)}</h1>
          <p><b>Date:</b> {checkString(data.Date)}</p>
          <p><b>Location:</b> {checkString(data.Country)}</p>
          <p><b>Description:</b> {checkString(data.Description)}</p>
          
          <h3>Mission Narrative:</h3>

          <p dangerouslySetInnerHTML={{__html: data?.Narrative?.Description}}></p>
          {/* <p>{data.Narrative.Source}</p> */}

          {data.MdDescription && <p dangerouslySetInnerHTML={{ __html: checkString(data.MdDescription) }} />}
          
          <p> <b>Notes:</b> {checkString(data.Notes)}</p>
          <p><b>Source:</b> {checkString(data.SourceSite)}</p>
          <h3>Segments</h3>
          { data.Segments ? (data.Segments).map(seg => (
              <Segment key={seg.name} segment={seg} />
          )) : <h4>No Segments Found for this Mission</h4>
          }
          <hr/>
          <h3>Group References</h3>
          { data.GroupReferences ? (data.GroupReferences).map(grRef => (
              <GroupReference key={counter} grRef={grRef} />
          )) : <h4>No Group References found for this Mission</h4>
          }
          <hr/>
          <h3>Reports</h3>
          {data.Reports?.map(r => <div>
            <p dangerouslySetInnerHTML={{__html: r.Description}}></p>
            <p><i><b>Source:</b> {r.Source}</i></p>
          </div>)}
          <hr/>
          <h3>Accidents</h3>
          {data.Accidents?.map(r => <div>
            <p dangerouslySetInnerHTML={{__html: r.Description}}></p>
            <p><i><b>Source:</b> {r.Source}</i></p>
          </div>)}
          <hr/>
          <h3>MIA's</h3>
          {data.MissingInAction?.map(r => <div>
            {r.AcName && <p><b>Aircraft Name:</b> {r.AcName}</p>}
            {r.Model && <p><b>Model:</b> {r.Model}</p>}
            {r.ModelShort && <p><b>Model Short:</b> {r.ModelShort}</p>}
            {r.Serial && <p><b>Serial:</b> {r.Serial}</p>}
            {r.SourceSite && <p><b>Source:</b> {r.SourceSite}</p>}
            <p>{r.Description}</p>
            <hr/>
          </div>)}
        </div>
      </Div>
    </Container>
  );
}

export default SingleMissionsContent;