import styled from 'styled-components'
import { Link } from 'react-router-dom';

function BackButton(props) {
    const Container = styled.div`
      background-color: rgb(34 51 59 / 13%);
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      color: white;
      margin-bottom: 30px;
      box-shadow: 0px 1px 19px 0 rgb(0 0 0 / 26%);
      border-top: 1px solid #C9B897;
      border-bottom: 1px solid #C9B897;
      @media(max-width:600px){
        margin-top: 5px;
        min-height: 60px;
      }     
    `;
  
    const Div = styled.div`
        display: flex;
        flex-direction: row;
        align-items: center;
        background: transparent;
        justify-content: center;
        width: 100%;
        min-height: 80px;
        position: relative;
        opacity: 90%;
    `;

    const Bar = styled.div`
      width: 60%;
      height: 1px;
      // border-bottom: 2px solid #C9B897;
      // margin: 0 0 20px 0;
    `;
  
    const Text = styled.div`
      color: #22333B;
      font-size: 32px;
      text-transform: uppercase;
      font-family: 'Rubik',sans-serif;
      font-weight: 500;
      display: flex;
      align-items: center;
      svg#Capa_1 {
        width: 28px;
        height: 28px;
        fill: #22333B;
        margin-right: 10px;
    }
    `
  
    return (
      <Container>
          <Bar />
          <Link to={props.link} >
            <Div>
                <Text><svg id="Capa_1" enable-background="new 0 0 511.999 511.999" height="512" viewBox="0 0 511.999 511.999" width="512" xmlns="http://www.w3.org/2000/svg"><path d="m346.999 457.516h-196v-90h196c41.355 0 75-33.645 75-75s-33.645-75-75-75h-170v73.032l-176.999-118.032 176.999-118.032v73.032h170c90.981 0 165 74.019 165 165s-74.019 165-165 165z"/></svg>BACK</Text>
            </Div>
          </Link>
          <Bar />
      </Container>
    );
  }
    
  export default BackButton;