import styled from 'styled-components';
import { useRef } from 'react';
import Navigation from '../navigation/navigation';
import SourcesInfiniteList from './sourceInfiniteList';
import SectionSidebarCard from '../utils/sectionSidebarCard';
import FilterListSources from '../utils/filterLists/filterListSources';

function SourcesPage(props) {

  function handleQuery(filters) {
    updateQueryFunc.current(filters);
  }

  const updateQueryFunc = useRef(null)

  const Container = styled.div`

  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 56px 1fr;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  width: 100%;
`;

const NestedContainer = styled.div`
width: 100%;
display: grid;
grid-template-columns: 1fr 4fr;
grid-template-rows: 1fr;
grid-column-gap: 0px;
grid-row-gap: 0px;
margin-top: 70px;
@media (max-width:1024px){
  grid-template-columns: 1fr 2fr;
}
@media (max-width:768px){
  grid-template-columns: 1fr 1fr;
}
@media (max-width:600px){
  grid-template-columns: auto;
}
`;

const SidebarHeaderContainer = styled.div`
display: flex;
flex-direction: column;
justify-content: start;
max-width: 100%;
`;

return (
  <Container className='main'>
      <Navigation />
      <NestedContainer>
        <SidebarHeaderContainer>
          <SectionSidebarCard link="/sources" title="Sources" tagline="The sources that have helped build this collection of information" bg="#605E5B" img={`${process.env.REACT_APP_API_BASE_URL}/img/site/42-94786.jpg`} />
          <FilterListSources isFiltered={handleQuery}/>
        </SidebarHeaderContainer>
        <SourcesInfiniteList filterQuery={updateQueryFunc}/>
      </NestedContainer>
  </Container>
);
}

export default SourcesPage;