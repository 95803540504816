import styled from 'styled-components'
import "react-checkbox-tree/lib/react-checkbox-tree.css";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  background: #22333b;
  justify-content: center;
`;

const Form = styled.div`
    display: flex;
    flex-direction: column;
    gap: 30px;
    padding: 30px;
    width: inherit;
`

const FormGroup = styled.div`
    width: 90%;
    label, input {
        display: block;
    }
`

const TextInput = styled.input`
    width: calc(100% - 10px);
    margin-top: 15px;
    height: 2rem;
    padding: 5px 10px;
`

const Label = styled.label`
    color: white;
    font-size: 18px;
    font-family: 'Rubik', sans-serif;
`

function FilterListHonorRoll({filters, setFilters}) {
    return (
        <Container className='category-list'>
            <Form>
                <FormGroup>
                    <Label for='lastNameFilter'>Last Name Begins With:</Label>
                    <TextInput id='lastNameFilter' type='text' onChange={e => setFilters({...filters, lastNameStartWith: e.target.value})} />
                </FormGroup>

                <FormGroup>
                    <Label for='intialsFilter'>Intials:</Label>
                    <TextInput id='intialsFilter' type='text' onChange={e => setFilters({...filters, initials: e.target.value})} />
                </FormGroup>

                <FormGroup>
                    <Label for='rankFilter'>Rank:</Label>
                    <TextInput id='rankFilter' type='text' onChange={e => setFilters({...filters, rank: e.target.value})} />
                </FormGroup>

                <FormGroup>
                    <Label for='branchFilter'>Branch:</Label>
                    <TextInput id='branchFilter' type='text' onChange={e => setFilters({...filters, branch: e.target.value})} />
                </FormGroup>
            </Form>
        </Container>
    );
}

export default FilterListHonorRoll;
