
import { useEffect, useState } from 'react'
import axios from 'axios'

export default function useInfiniteSearch(url, query, pageNumber) {
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(false)
  const [datas, setDatas] = useState([])
  const [hasMore, setHasMore] = useState(false)

  useEffect(() => {
    setDatas([])
  }, [query])

  let prms = query;
  prms.page = pageNumber;

  useEffect(() => {
    setLoading(true)
    setError(false)
    let cancel
    axios({
      method: 'GET',
      url: url,
      params: prms,
      cancelToken: new axios.CancelToken(c => cancel = c)
    }).then(res => {
      setDatas(prevDatas => {
        return [...new Set([...prevDatas, ...isEmptyReturn(res.data.Data)])]
        // return [...new Set([...prevDatas, ...res.data.Data.map(b => b)])]
      })
      setHasMore(res.data.Pagination.TotalPages !== pageNumber)
      setLoading(false)
    }).catch(e => {
      if (axios.isCancel(e)) return
      setError(true)
    })
    return () => cancel()
  }, [query, pageNumber, url, prms])

  return { loading, error, datas, hasMore }
}


function isEmptyReturn(data) {
  if (data && data.length > 0) {
    return data.map(b => b);
  }
  return [];
}