import './App.css';
import { Route, BrowserRouter, Switch } from 'react-router-dom'
import TitleBar from './components/utils/titlebar'
import Home from './components/Home/home'
import PhotosPage from './components/photos/photosPage';
import AircraftsPage from './components/aircraft/aircraftsPage';
import ChartsPage from './components/charts/chartsPage';
import SourcesPage from './components/source/sourcesPage';
import GroupsPage from './components/groups/groupsPage';
import BasesPage from './components/bases/basesPage';
import TargetsPage from './components/targets/targetsPage';
import MissionsPage from './components/missions/missionsPage';
import SingleChartContent from './components/charts/singleChartContent';
import SingleAircraftContent from './components/aircraft/singleAircraftContent';
import SinglePhotoContent from './components/photos/singlePhotoContent';
import SingleBaseContent from './components/bases/singleBaseContent';
import SingleTargetsContent from './components/targets/singleTargetContent';
import SingleMissionsContent from './components/missions/singleMissionsContent';
import SingleGroupContent from './components/groups/singleGroupContent';
import SearchPage from './components/utils/search/searchPage';
import A404Page from './components/a404/a404page';
import HonorRoll from './components/honorroll';

function App() {
  return (
    <BrowserRouter>
      <TitleBar />
      <div style={{ marginTop: 80 }}>
        <Switch>
          <Route path="/photos/:id" component={SinglePhotoContent} />
          <Route path="/photos" component={PhotosPage} />
          <Route path="/aircraft/:id" component={SingleAircraftContent} />
          <Route path="/aircraft" component={AircraftsPage} />
          <Route path="/charts/:id" component={SingleChartContent} />
          <Route path="/charts" component={ChartsPage} />
          <Route path="/sources" component={SourcesPage} />
          <Route path="/groups/:id" component={SingleGroupContent} />
          <Route path="/groups" component={GroupsPage} />
          <Route path="/bases/:id" component={SingleBaseContent} />
          <Route path="/bases" component={BasesPage} />
          <Route path="/targets/:id" component={SingleTargetsContent} />
          <Route path="/targets" component={TargetsPage} />
          <Route path="/missions/:id" component={SingleMissionsContent} />
          <Route path="/missions" component={MissionsPage} />
          <Route path="/search" component={SearchPage} />
          <Route path="/honor_roll" component={HonorRoll} />
          <Route path="/" component={Home} />
          <Route path="*" component={A404Page} />
        </Switch>
      </div>
    </BrowserRouter>
  );
}

export default App;
