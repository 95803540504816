import styled from 'styled-components'

function Segment(props) {

    const Container = styled.div`
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
    `;

    return (
      <Container>
            <h4>{props.segment.SegmentName}</h4>
            <p>Sent: {props.segment.Sent}</p>
            <p>Bomb: {props.segment.Bomb}</p>
            <p>Tonnage: {props.segment.Tonnage}</p>
            <p>Notes: {props.segment.Notes}</p>
      </Container>
    );
  }
    
  export default Segment;