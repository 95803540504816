import styled from 'styled-components'

function AirfieldListItem(props) {

  const Container = styled.div`
    width: 93%;
    min-height: 100px;
    margin: 20px auto;
    padding: 10px;
    background: #22333B;
  `;

  const P = styled.p`
    color: #FFF;
    margin: 0;
    width: 100%;
    text-align: center;
    @media(max-width:600px){
      margin-bottom: 10px;
    }
  `;

  const Div = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    background: transparent;
    justify-content: center;
    width: 100%;
    min-height: 100px;
    position: relative;
    opacity: 90%;   
    @media(max-width:600px){
      flex-direction: column;
    }
  `;

  function checkString(stringData) {
    if (stringData && stringData != "") {
      return stringData;
    }

    return "N/A";
  }

  return (
    <Container>
        <Div>
            <P><strong>Name:</strong> {checkString(props.name)}</P>
            <P><strong>Arrival:</strong> {checkString(props.arrival)}</P>
            <P><strong>Depart:</strong> {checkString(props.depart)}</P>
        </Div>
    </Container>
  );
}

export default AirfieldListItem;