
import React, { useState, useRef, useCallback } from 'react'
import ChartsListItem from './chartsListItem';
import useInfiniteSearch from '../utils/useInfiniteSearch';
import styled from 'styled-components'

export default function ChartsInfiniteList() {

    const Container = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
  `;

  const [query, setQuery] = useState({})
  const [pageNumber, setPageNumber] = useState(1)

  const {
    datas,
    hasMore,
    loading,
    error
  } = useInfiniteSearch(`${process.env.REACT_APP_API_BASE_URL}/api/charts`, query, pageNumber)

  const observer = useRef()
  
  const lastItemElementRef = useCallback(node => {
    if (loading) return
    if (observer.current) observer.current.disconnect()
  
    observer.current = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting && hasMore) {
        setPageNumber(prevPageNumber => prevPageNumber + 1)
      }
    })
    if (node) observer.current.observe(node)
  }, [loading, hasMore])

  function handleSearch(e) {
    // add parts to make string from filters area
    setPageNumber(1)
  }

  function checkString(stringData) {
    if (stringData && stringData != "") {
      return stringData;
    }

    return "N/A";
  }

  return (
    <>
        <Container>
            {datas.map((item, index) => {
              if (datas.length > 15 && index === datas.length - 10) {
                return <><ChartsListItem key={item.Id} descr={checkString(item.Description)} from='/' source={checkString(item.Source_Site)} link={`/charts/${item.Id}`}/><div ref={lastItemElementRef}></div></>
              } else {
                return <ChartsListItem key={item.Id} descr={checkString(item.Description)} from='/' source={checkString(item.Source_Site)} link={`/charts/${item.Id}`}/>
              }
            })}
            <div>{loading && 'Loading...'}</div>
            <div>{error && 'Error'}</div>
        </Container>
    </>
  )
}