import styled from 'styled-components'
import { useHistory } from 'react-router-dom';

function TitleBar() {

  const AppHeader = styled.div`
    background-color: #282c34;
    min-height: 80px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    color: white;
    padding: 0 20px;
    @media (max-width: 768px) {
      /* justify-content:center;*/
      padding: 10px 20px;
    }
    @media (max-width: 600px) {
      justify-content:space-between;
      flex-wrap: wrap;
      padding: 10px 20px;
    }
  `;

  const AppHeaderTitle = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    h2{
      font-family: 'Rubik', sans-serif;
      font-weight: 700;
      font-size: 24px;
      line-height: 36px;
      @media (max-width: 991px) {
        font-size: 18px;
        line-height: 26px;
      }
      @media (max-width: 658px) {
        font-size: 16px;
        line-height: 22px;
      }
      
    };
  `

  const Img = styled.img`
    width: 70px;
    height: 70px;
    margin: 5px 20px 5px 0px;
  `

  const Input = styled.input`
    border: none;
    background: transparent;
    margin: 0;
    padding: 7px 8px;
    font-size: 14px;
    color: #000;
    border: 1px solid transparent;
    border-radius: inherit;
    font-family: 'Rubik', sans-serif;
  `

  const Button = styled.button`
    text-indent: -999px;
    overflow: hidden;
    width: 40px;
    padding: 0;
    margin: 0;
    border: 1px solid transparent;
    border-radius: inherit;
    background: transparent url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' class='bi bi-search' viewBox='0 0 16 16'%3E%3Cpath d='M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z'%3E%3C/path%3E%3C/svg%3E") no-repeat center;
    cursor: pointer;
    opacity: 0.8;
  `

  const history = useHistory();

  const handleSubmit = (event) => {
    event.preventDefault();
    const query = document.querySelector('#searchInput').value
    if (query !== "") {
      history.push(`/search?q=${query}`);
    } else {
      alert('Cannot Search With An Empty Query');
    }
  }


  return (
    <div className='header'>
      <AppHeader >
        <AppHeaderTitle>
          <Img src={`${process.env.REACT_APP_API_BASE_URL}/img/site/cropped_8thafhf_logo.png`} style={{ width: 70, height: 70 }} />
          <h2>8th Air Force Operations Research Database</h2>
        </AppHeaderTitle>
        <form onSubmit={handleSubmit} className='form-group'>
          <Input
            type="text" placeholder="Search..." id="searchInput" />
          <Button type='submit' className='submitbtn'> Submit</Button>
        </form>
      </AppHeader>
    </div>
  );
}

export default TitleBar;