
import React, { useEffect, useState, useRef, useCallback } from 'react'
import AircraftListItem from './aircraftsListItem';
import useInfiniteSearch from '../utils/useInfiniteSearch';
import styled from 'styled-components'

export default function AircraftInfiniteList(props) {

    const Container = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
  `;

  const [query, setQuery] = useState({})
  const [pageNumber, setPageNumber] = useState(1)

  const handleSearch = useCallback((filters) => {
    setQuery({letters: filters.join(',')})
    setPageNumber(1)
  }, [setQuery, setPageNumber])

  useEffect(() => {
    props.filterQuery.current = handleSearch
  }, [props.filterQuery.current, handleSearch]);

  const {
    datas,
    hasMore,
    loading,
    error
  } = useInfiniteSearch(`${process.env.REACT_APP_API_BASE_URL}/api/aircraft`, query, pageNumber)

  const observer = useRef()
  
  const lastItemElementRef = useCallback(node => {
    if (loading) return
    if (observer.current) observer.current.disconnect()
  
    observer.current = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting && hasMore) {
        setPageNumber(prevPageNumber => prevPageNumber + 1)
      }
    })
    if (node) observer.current.observe(node)
  }, [loading, hasMore])

  function checkString(stringData) {
    if (stringData && stringData != "") {
      return stringData;
    }

    return "N/A";
  }

  return (
    <>
        <Container>
            {datas.map((item, index) => {
              if (datas.length > 15 && index === datas.length - 10) {
                return <><AircraftListItem key={item.Id} descr={checkString(item.Description)} source={checkString(item.Source_Site)} link={`/aircraft/${item.Id}`}/><div ref={lastItemElementRef}></div></>
              } else {
                return <AircraftListItem key={item.Id} descr={checkString(item.Description)} source={checkString(item.Source_Site)} link={`/aircraft/${item.Id}`}/>
              }
            })}
            <div>{loading && 'Loading...'}</div>
            <div>{error && 'Error'}</div>
        </Container>
    </>
  )
}
