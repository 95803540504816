import styled from 'styled-components';
import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Navigation from '../navigation/navigation';
import BackButton from '../utils/BackButton';

function SingleBaseContent(props) {
  let { id } = useParams();

  const Container = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 56px 1fr;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  margin-top: 80px;
  @media (max-width:600px){
    margin-top:136px;
  }
  `;
  const Div = styled.div`
  width: 100%;
  margin-top: 52px;
  h3{
    width:50%;
    text-align:center;
    margin: 0 auto;
  }
  p{
    color: #22333B;
    font-family: 'Rubik',sans-serif;
    font-weight: 400;
    font-size:18px;
    text-align: justify;
    word-break: break-word;;
    line-height: 23px;
    @media (max-width:600px){
      font-size:14px;
    }
  }
  b{
    font-size:20px;  
  }
  img{
    max-width:100%;
    height:auto;
  }
`;

  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  let url = `${process.env.REACT_APP_API_BASE_URL}/api/bases/${id}`;

  useEffect(() => {
    setIsLoading(true);
    fetch(url)
      .then((res) => res.json())
      .then((data) => {
        setData(data);
      })
      .catch((err) => {
        setHasError(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  if (isLoading) {
    return <h1>Loading...</h1>;
  }

  if (hasError || !data) {
    return <p>Something went wrong!!!</p>;
  }
  return (
    <Container>
       <Navigation />
      <Div>
        <BackButton link={props.location.from} />
        <div className='description-container'>
            <h1>Name: {data.Name}</h1>
            <p><b>Details:</b> {data.Description}</p>
            <p> <b>Location:</b> {data.City}, {data.Country}</p>
            <p><b>Station ID:</b> {data.StationId} - Station Number: {data.StationNumber} - Coordinates: {data.Lat}, {data.Lon}</p>
            {data.SitePlanFname && <img src={`${process.env.REACT_APP_API_BASE_URL}/img/${data.SitePlanFname}`} />}
            <h3> <b>Description:</b></h3>
              <p dangerouslySetInnerHTML={{__html: data.LongDescription}}></p>
            <p><b>Link:</b> {data.Link}</p>
            </div>
      </Div>
    </Container>
  );
}

export default SingleBaseContent;