import styled from 'styled-components'
import { useState, useEffect } from 'react';
// import FilterCheckBox from '../filterCheckBox';
import CheckboxTree from "react-checkbox-tree";
import "react-checkbox-tree/lib/react-checkbox-tree.css";

import {
  MdCheckBox,
  MdCheckBoxOutlineBlank,
  MdChevronRight,
  MdKeyboardArrowDown,
  MdAddBox,
  MdIndeterminateCheckBox,
  MdFolder,
  MdFolderOpen,
  MdInsertDriveFile
} from "react-icons/md";

function FilterListAircraft(props) {
  
  const [checked, setChecked] = useState([]);
  const [expanded, setExpanded ] = useState([]);

  function updateQuery(filters) {
    setChecked(filters);
    props.isFiltered(filters);
  }

    const Container = styled.div`
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    `;

    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [hasError, setHasError] = useState(false);
    let url = `${process.env.REACT_APP_API_BASE_URL}/api/filters/aircraft`;
  
    if (window.location.search) {
      url = url + window.location.search;
    } 
  
    useEffect(() => {
      setIsLoading(true);
      fetch(url)
        .then((res) => res.json())
        .then((data) => {
          setData(data);
        })
        .catch((err) => {
          setHasError(err);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }, [url, props]);
  
    if (isLoading) {
      return <h1>Loading...</h1>;
    }
  
    if (hasError) {
      return <p>Something went wrong!!!</p>;
    }

    let lettersArray = [];

    data.letters.map((item) => {
      if(item) {
        lettersArray.push({ value: item, label: item })
      }
    }); 

    const nodes = [
      {
        value: data.letters,
        label: "letters",
        children: lettersArray,
        className: "Aletters"
      }            
    ];    
    
    const icons = {
      check: <MdCheckBox className="rct-icon rct-icon-check" />,
      uncheck: <MdCheckBoxOutlineBlank className="rct-icon rct-icon-uncheck" />,
      halfCheck: (
        <MdIndeterminateCheckBox className="rct-icon rct-icon-half-check" />
      ),
      expandClose: (
        <MdChevronRight className="rct-icon rct-icon-expand-close" />
      ),
      expandOpen: (
        <MdKeyboardArrowDown className="rct-icon rct-icon-expand-open" />
      ),
      expandAll: <MdAddBox className="rct-icon rct-icon-expand-all" />,
      collapseAll: (
        <MdIndeterminateCheckBox className="rct-icon rct-icon-collapse-all" />
      ),
      parentClose: <MdFolder className="rct-icon rct-icon-parent-close" />,
      parentOpen: <MdFolderOpen className="rct-icon rct-icon-parent-open" />,
      leaf: <MdInsertDriveFile className="rct-icon rct-icon-leaf-close" />
    };

    return (
      <Container className='category-list'>
        <CheckboxTree
        nodes={nodes}
        checked={checked}
        expanded={expanded}
        onCheck={checked => updateQuery( checked )}
        onExpand={expanded => setExpanded(expanded)}
        icons={icons}
      />
            {/* <h3>Letter</h3>
            {(data.letters).map((letter, index) => (
                <FilterCheckBox key={index} onChange={updateQuery} filterClass="letters" opt={letter} />
            ))} */}
      </Container>
    );
  }
    
  export default FilterListAircraft;