import styled from 'styled-components';
import Navigation from '../navigation/navigation';
import MissionsInfiniteList from './missionsInfiniteList';
import SectionSidebarCard from '../utils/sectionSidebarCard';
import FilterListMissions from '../utils/filterLists/filterListMissions';
import React, { useRef } from 'react'

const Container = styled.div`
width: 100%;
display: grid;
grid-template-columns: 1fr;
grid-template-rows: 56px 1fr;
grid-column-gap: 0px;
grid-row-gap: 0px;
`;

const NestedContainer = styled.div`
width: 100%;
display: grid;
grid-template-columns: 1fr 4fr;
grid-template-rows: 1fr;
grid-column-gap: 0px;
grid-row-gap: 0px;
margin-top: 70px;
@media (max-width:1024px){
grid-template-columns: 1fr 2fr;
}
@media (max-width:768px){
grid-template-columns: 1fr 1fr;
}
@media (max-width:600px){
grid-template-columns: auto;
}
`;

const SidebarHeaderContainer = styled.div`
display: flex;
flex-direction: column;
justify-content: start;
max-width: 100%;
`;

function MissionsPage() {
  const updateQueryFunc = useRef(null)

  function handleQuery(filters) {
    updateQueryFunc.current(filters);
  }

  return (
    <Container className='main'>
      <Navigation />
      <NestedContainer>
        <SidebarHeaderContainer>
          <SectionSidebarCard link="/missions" title="Missions" tagline="View Eighth Air Force missions for a specific date" bg="#605E5B" img={`${process.env.REACT_APP_API_BASE_URL}/img/site/mission.jpg`} />
          <FilterListMissions isFiltered={handleQuery} />
        </SidebarHeaderContainer>
        <MissionsInfiniteList filterQuery={updateQueryFunc} />
      </NestedContainer>
    </Container>
  );
}

export default MissionsPage;