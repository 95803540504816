import styled from 'styled-components'

function GroupReference(props) {

    const Container = styled.div`
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
    `;
    const Strong = styled.span`
    font-weight: 600;
  `;
    const groupings = (g1, g2, g3, g4, g5, g6, g7, g8) => {
        let array = [g1, g2, g3, g4, g5, g6, g7, g8];
        let res = ""
        array.forEach(g => {
            if (g) {
                res = `${res}, ${g}`;
            }
        });
        if (res.substring(0, 2) === ' ,') {
            res = res.substring(2);
        }
        if (res.substring(0, 1) === ',') {
            res = res.substring(1);
        }
        if (res.substring(res.length - 2) === ' ,') {
            res = res.substring(0, res.length - 2);
        }
        return res
    }

    return (
      <Container>
            <p><Strong>Mission Type</Strong>: {props.grRef.MissionType}, <Strong>Target</Strong>: {props.grRef.Target}, <Strong>Country</Strong>: {props.grRef.Country}
            , <Strong>Groups Involved</Strong>: {groupings(props.grRef.Group1, props.grRef.Group2, props.grRef.Group3, props.grRef.Group4, props.grRef.Group5, props.grRef.Group6, props.grRef.Group7, props.grRef.Group8)}</p>
      </Container>
    );
  }
    
  export default GroupReference;