import styled from 'styled-components';
import {Link, useLocation} from 'react-router-dom'

function NavButton(props) {
  console.log( props.label, " props.label");
  const { pathname } = useLocation();
  console.log(pathname, "pathname")

  const Container = styled.div`
     width: 20%;
    // height: 42px;
    // margin: 0 10px 0 10px;
    padding:0 20px;
    // background: #50656E;
    // margin:0 auto;   
  `;

  const P = styled.p`
    color: #fff;
    margin: 0;
    width: 100%;
    font-size: 20px;
    text-align: center;
    font-weight: 600;
    text-transform: uppercase;
    font-family: 'Rubik',sans-serif;   
    @media (max-width: 600px){
      text-align: left;
    }
  `;
  
  const Div = styled.div`
    display: flex;
    align-items: center;
    background: transparent;
    justify-content: center;
    width: 100%;
    height: 42px;
    position: relative;
    opacity: 90%; 
  `;

  return (
    <Container>
      <Link to={props.link} >
        <Div className={(pathname.replace('/','')) === props.label.toString().toLowerCase() ? 'active' : ''}>
          <P>{props.label}</P>
        </Div>
      </Link>
    </Container>
  );
}

export default NavButton;