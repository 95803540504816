import styled from 'styled-components'
import {Link} from 'react-router-dom'


function HomeMissionListItem(props) {

  const Container = styled.div`
    width: 85%;
    min-height: 70px;
    margin: 20px auto;
  `;

  const P = styled.p`
    color: #FFF;
    margin: 0;
    width: 100%;
    text-align: center;
    white-space: break-spaces;
    word-break: break-word;
  `;
  
  const Div = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    background: ${props.bg};
    justify-content: center;
    min-height: 70px;
    position: relative;
    opacity: 90%;
    padding: 10px;
  `;

  const Bold = styled.span`
    font-weight: 600;
  `;

  return (
    <Container>
      <Link to={props.link}>
        <Div>
          <P>Mission <Bold>{props.name}</Bold> on {dateFormat(props.date)} with operation: <Bold>{props.descr}</Bold></P>
        </Div>
      </Link>
    </Container>
  );
}

export default HomeMissionListItem;

const dateFormat = (stamp) => {
  let dt = new Date(stamp);
  return dt.toLocaleString('en-US', {
    weekday: 'short', // long, short, narrow
    day: 'numeric', // numeric, 2-digit
    year: 'numeric', // numeric, 2-digit
    month: 'long', // numeric, 2-digit, long, short, narrow
  });
}

