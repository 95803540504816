import styled from 'styled-components';

function A404Message(props) {

  const Container = styled.div`
  width: 100%;
  min-height: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  `;

  return (
    <Container>
      <h1>Sorry Ace</h1>
      <h3>This page seems to be a real bupkis...</h3>
      <h3>Please navigate to the home or other area.</h3>
    </Container>
  );
}

export default A404Message;