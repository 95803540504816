import styled from 'styled-components'

function HorzontalRule(props) {

    const Container = styled.div`
      background-color: #22333B;
      min-height: 100px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      color: white;
      margin-bottom: 30px;
    `;
  
    const Bar = styled.div`
      width: 60%;
      height: 1px;
      border-bottom: 2px solid #C9B897;
      margin: 10px 0 10px 0;
    `;
  
    const Text = styled.div`
      color: #C9B897;
      font-size: 32px;
      text-transform: uppercase;
      font-family:'Rubik', sans-serif;
    `
  
    return (
      <Container>
          <Bar />
          <Text>{props.text}</Text>
          <Bar />
      </Container>
    );
  }
    
  export default HorzontalRule;