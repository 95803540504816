import styled from 'styled-components';
import NavButton from './navButton';
import { FaBars } from 'react-icons/fa';
import { useState } from 'react';
function Navigation() {
  const [toggle, setToggle] = useState("");
  const [bugger, setBugger] = useState("");
  const toggleAccordion = () => {
    setToggle(toggle === "" ? "menu-active" : "");
    setBugger(bugger === "" ? "bugger-active" : "");
  }
  const Container = styled.div`
    width: 100%;
    background: #282c34;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    overflow: auto;   
    @media (max-width: 600px){
    flex-direction: column;
    min-height: 395px;
    z-index: 1;
    align-items: flex-start;
    padding: 10px 0;
    display:none;
  }
    
  `;

  return (
    <>
      <div className='navbar'>
        <div className={`hamburger-icon ${bugger}`} onClick={toggleAccordion}><FaBars /></div>

        <Container className={`navbar-menu ${toggle}`}>
          <NavButton label="Home" link="/" />
          <NavButton label="Groups" link="/groups" />
          <NavButton label="Bases" link="/bases" />
          <NavButton label="Targets" link="/targets" />
          <NavButton label="Missions" link="/missions" />
          <NavButton label="Aircraft" link="/aircraft" />
          <NavButton label="Photos" link="/photos" />
          <NavButton label="Charts" link="/charts" />
          <NavButton label="Sources" link="/sources" />
        </Container>
      </div>
    </>
  );
}

export default Navigation;