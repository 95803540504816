import styled from 'styled-components'
import { Link } from 'react-router-dom'

const Container = styled.div`
    width: 93%;
    min-height: 70px;
    margin: 20px auto;
    background: #22333B;
  `;

const P = styled.p`
    color: #FFF;
    margin: 0;
    width: 100%;
    text-align: center;
    line-height:28px;
      b{
        font-weight:500;
      }
  `;

const Div = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    background: transparent;
    justify-content: center;
    min-height: 70px;
    position: relative;
    opacity: 90%;   
    padding: 12px;
    @media (max-width: 1024px) {
      flex-direction: column;
    }
  `;

function HonorRollListItem({ data }) {
    return (
        <Container>
            <Div>
                <P><b>Last Name:</b> {data.LastName}</P>
                <P><b>Initials:</b> {data.Initials}</P>
                <P><b>Rank:</b> {data.Rank}</P>
                <P><b>Branch:</b> {data.Branch}</P>
                {/* <P><b>Source:</b> Honor Roll Page {data.Page}</P> */}
            </Div>
        </Container>
    );
}

export default HonorRollListItem;
