import styled from 'styled-components';
import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Navigation from '../navigation/navigation';
import BackButton from '../utils/BackButton';

function SingleChartContent(props) {

  let { id } = useParams();

  const Container = styled.div`
    width: 100%;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 56px 1fr;
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    margin-top: 80px;
    @media (max-width:600px){
      margin-top:136px;
    }
  `;
  const Div = styled.div`
  width: 100%;
  margin-top: 52px;
    h1{
      padding: 0 10px;
      text-align:center;
    }
    p{
      font-weight: 400;  
      text-align:center;
      @media (max-width:600px){
        font-size:14px;
      }
    }
    img{
      max-width: 100%;
      height: auto;
    }
`;


  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  let url = `${process.env.REACT_APP_API_BASE_URL}/api/charts/${id}`;

  useEffect(() => {
    setIsLoading(true);
    fetch(url)
      .then((res) => res.json())
      .then((data) => {
        setData(data);
      })
      .catch((err) => {
        setHasError(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  if (isLoading) {
    return <h1>Loading...</h1>;
  }

  if (hasError || !data) {
    return <p>Something went wrong!!!</p>;
  }

  function checkString(stringData) {
    if (stringData && stringData != "") {
      return stringData;
    }

    return "N/A";
  }

  const imgUrl = `${process.env.REACT_APP_API_BASE_URL}/img/documents/${data.Filename}`;

  return (
    <Container>
      <Navigation />
      <Div>
        <BackButton link={props.location.from} />
        <div className='description-container'>
          <h1>Description: {checkString(data.Description)}</h1>
          <p><strong>Type:</strong> {checkString(data.DocType)} / {checkString(data.SubType)}</p>
          <p><strong>Notes:</strong> {checkString(data.Notes)}</p>
          <img src={imgUrl} />
          <p><strong>Source:</strong> {checkString(data.Source_Notes)} - {checkString(data.Source_Site)}</p>
        </div>
      </Div>
    </Container>
  );
}

export default SingleChartContent;