import styled from 'styled-components'
import {Link} from 'react-router-dom'


function HomeSectionCard(props) {

  const Container = styled.div`
    width: 20%;
    min-height: 200px;
    margin: 20px 10px;
    background-image: url(${props.img});
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    @media (max-width: 1024px) {
      width: 46%;
    }
    @media (max-width: 575px) {
      width: 100%;
    }
  `;

  const H3 = styled.h3`
    color: #C9B897;
    font-size: 32px;
    font-weight: 600;
    width: 100%;
    text-align: center;
    margin 0;
  `;
  
  const P = styled.p`
    color: #FFF;
    margin-top:20px;
    width: 80%;
    text-align: center;
  `;
  
  const Div = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    background: ${props.bg};
    justify-content: center;
    width: 100%;
    min-height: 200px;
    position: relative;
    opacity: 90%;
    flex-wrap: wrap;
`;

  return (
    <Container>
      <Link to={props.link}>
        <Div>
          <H3>{props.title}</H3>
          <P>{props.tagline}</P>
        </Div>
      </Link>
    </Container>
  );
}

export default HomeSectionCard;