import styled from 'styled-components'
import {Link} from 'react-router-dom'

function PhotosListItem(props) {

  const Container = styled.div`
    width: 93%;
    min-height: 70px;
    margin: 20px auto;
    background: #22333B;
  `;

  const P = styled.p`
    color: #FFF;
    margin: 0;
    width: 100%;
    text-align: center;
    line-height: 28px;
    b{
      font-weight:500;
    }
  `;
  
  const Div = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    background: transparent;
    justify-content: center;
    min-height: 70px;
    position: relative;
    opacity: 90%;
    padding: 12px;
    @media (max-width: 1024px) {
      flex-direction: column;
    }
  `;

  return (
    <Container>
      <Link to={{pathname: props.link, from: window.location.pathname + window.location.search }}>
        <Div>
            <P><b>Location:</b> {props.country}</P>
            <P><b>Description:</b> {props.descr}</P>
            <P><b>Source:</b> {props.source}</P>
        </Div>
      </Link>
    </Container>
  );
}

export default PhotosListItem;