import React, { useState, useRef, useCallback, useEffect } from 'react'
import MissionsListItem from './missionsListItem';
import useInfiniteSearch from '../utils/useInfiniteSearch';
import styled from 'styled-components'

const Container = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
  `;

export default function MissionInfiniteList(props) {
  useEffect(() => {
    props.filterQuery.current = handleSearch
  }, []);

  const [query, setQuery] = useState({})
  const [pageNumber, setPageNumber] = useState(1)

  const {
    datas,
    hasMore,
    loading,
    error
  } = useInfiniteSearch(`${process.env.REACT_APP_API_BASE_URL}/api/missions`, query, pageNumber)

  const observer = useRef()

  const lastItemElementRef = useCallback(node => {
    if (loading) return
    if (observer.current) observer.current.disconnect()

    observer.current = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting && hasMore) {
        setPageNumber(prevPageNumber => prevPageNumber + 1)
      }
    })
    if (node) observer.current.observe(node)
  }, [loading, hasMore])

  function handleSearch(q) {
    setQuery(q)
    setPageNumber(1)
  }

  function checkString(stringData) {
    if (stringData && stringData != "") {
      return stringData;
    }

    return "N/A";
  }

  return (
    <Container>
      {datas.map((item, index) => {
        if (datas.length > 15 && index === datas.length - 10) {
          return <><MissionsListItem key={item.Id} name={checkString(item.Name)} descr={checkString(item.Description)} date={checkString(item.Date)} link={`/missions/${item.Id}`} /><div ref={lastItemElementRef}></div></>
        } else {
          return <MissionsListItem key={item.Id} name={checkString(item.Name)} descr={checkString(item.Description)} date={checkString(item.Date)} link={`/missions/${item.Id}`} />
        }
      })}
      {datas.length === 0 && <h2>No Records Found</h2>}
      <div>{loading && 'Loading...'}</div>
      <div>{error && 'Error'}</div>
    </Container>
  )
}
