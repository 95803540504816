
import React, { useEffect, useState, useRef, useCallback } from 'react'
import TargetsListItem from './targetsListItem';
import useInfiniteSearch from '../utils/useInfiniteSearch';
import styled from 'styled-components'

const Container = styled.div`
width: 100%;
display: flex;
flex-direction: column;
align-items: center;
justify-content: flex-start;
`;

export default function TargetsInfiniteList(props) {
  const [query, setQuery] = useState({})
  const [pageNumber, setPageNumber] = useState(1)

  useEffect(() => {
    props.filterQuery.current = handleSearch
  }, []);

  const {
    datas,
    hasMore,
    loading,
    error
  } = useInfiniteSearch(`${process.env.REACT_APP_API_BASE_URL}/api/targets`, query, pageNumber)

  const observer = useRef()

  const lastItemElementRef = useCallback(node => {
    if (loading) return
    if (observer.current) observer.current.disconnect()

    observer.current = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting && hasMore) {
        setPageNumber(prevPageNumber => prevPageNumber + 1)
      }
    })
    if (node) observer.current.observe(node)
  }, [loading, hasMore])

  function handleSearch(filters) {
    setQuery({ location: JSON.stringify(filters['Location']), type: filters['Target Type'].join(',') })
    setPageNumber(1)
  }

  return (
    <>
      <Container>
        {datas.map((item, index) => {
          if (datas.length > 15 && index === datas.length - 10) {
            return <><TargetsListItem key={item.Id} name={nameCheck(item.Name)} location={locationBuilder(item.City, item.Country)} types={typeBuilder(item.TargetType, item.SubType)} link={`/targets/${item.Id}`} /><div ref={lastItemElementRef}></div></>
          } else {
            return <TargetsListItem key={item.Id} name={nameCheck(item.Name)} location={locationBuilder(item.City, item.Country)} types={typeBuilder(item.TargetType, item.SubType)} link={`/targets/${item.Id}`} />
          }
        })}
        <div>{loading && 'Loading...'}</div>
        <div>{error && 'Error'}</div>
      </Container>
    </>
  )
}

function locationBuilder(city, country) {
  let local = ""
  if (city) {
    local = city;
  }
  if (country) {
    if (local !== "") {
      local = `${local}, `;
    }
    local = local + country;
  }
  if (local === "") {
    local = 'Unknown Location';
  }
  return local;
}

function typeBuilder(targetType, subType) {
  let t = ""
  if (targetType) {
    t = targetType;
  }
  if (subType) {
    if (t !== "") {
      t = `${t}/`;
    }
    t = t + subType;
  }
  if (t === "") {
    t = 'Unknown Location';
  }
  return t;
}

function nameCheck(name) {
  if (name && name !== "") {
    return name;
  }
  return 'Unknown Target Name';
}