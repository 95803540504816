import React, { useState, useRef, useCallback, useEffect } from 'react'
import useInfiniteSearch from '../utils/useInfiniteSearch';
import styled from 'styled-components'
import HonorRollListItem from './HonorRollListItem';
import { mapValues, trim } from 'lodash';

const Container = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
  `;

export default function HonorRollInfiniteList({ filters }) {
    const [pageNumber, setPageNumber] = useState(1)
    // const getHonorRoll = useMemo(() => debounce((params) => axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/honor_roll/search`, { params }).then((res) => setHonorRoll(res.data.Data)), 500))

    const {
        datas,
        hasMore,
        loading,
        error
    } = useInfiniteSearch(`${process.env.REACT_APP_API_BASE_URL}/api/honor_roll/search`, filters, pageNumber)

    const observer = useRef()

    const lastItemElementRef = useCallback(node => {
        if (loading) return
        if (observer.current) observer.current.disconnect()

        observer.current = new IntersectionObserver(entries => {
            if (entries[0].isIntersecting && hasMore) {
                setPageNumber(prevPageNumber => prevPageNumber + 1)
            }
        })
        if (node) observer.current.observe(node)
    }, [loading, hasMore])

    return (
        <Container>
            {datas.map((item, index) => {
                if (datas.length > 15 && index === datas.length - 10) {
                    return <><HonorRollListItem key={item.Id} data={item} /><div ref={lastItemElementRef}></div></>
                } else {
                    return <HonorRollListItem key={item.Id} data={item} />
                }
            })}

            {datas.length === 0 && <h2>No Records Found</h2>}

            <div>{loading && 'Loading...'}</div>

            <div>{error && 'Error'}</div>
        </Container>
    )
}
