import styled from 'styled-components';
import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Navigation from '../navigation/navigation';
import BackButton from '../utils/BackButton';

function SingleAircraftContent(props) {

  let { id } = useParams();

  const Container = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 56px 1fr;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  margin-top: 80px;
  @media (max-width:600px){
    margin-top:136px;
  }

  `;
  const Div = styled.div`
  width: 100%;
  margin-top: 52px;
  h1 {
    width:100%;
    text-align:center;
    p{
      text-align:center;
    }
    ul li {
      font-size: 22px;
      line-height: 33px;
      margin-left: 27px;
      padding-top: 10px;
      text-align:left;
    }
  }
  p{
    color: #22333B;
    font-family: 'Rubik',sans-serif;
    font-weight: 400;
    font-size:18px;
    text-align: justify;
    @media (max-width:600px){
      font-size:14px;
    }
  }
  b{
    font-size:20px;  
  }  
  img{
    max-width:100%;
    height:auto;
  }
`;

  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  let url = `${process.env.REACT_APP_API_BASE_URL}/api/aircraft/${id}`;

  useEffect(() => {
    setIsLoading(true);
    fetch(url)
      .then((res) => res.json())
      .then((data) => {
        setData(data);
      })
      .catch((err) => {
        setHasError(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [url]);

  if (isLoading) {
    return <h1>Loading...</h1>;
  }

  if (hasError || !data) {
    return <p>Something went wrong!!!</p>;
  }

  function checkString(stringData) {
    if (stringData && stringData != "") {
      return stringData;
    }

    return "N/A";
  }

  const imgUrl = `${process.env.REACT_APP_API_BASE_URL}/img/aircraft/${data.Filename}`;

  return (
    <Container>
      <Navigation />
      <Div>
        <BackButton link={props.location.from} />
        <div className='description-container'>
          <h1 dangerouslySetInnerHTML={{ __html: data.Description }}></h1>
          <p><b>Type:</b> {checkString(data.DocType)} / {checkString(data.SubType)}</p>
          <p><b>Location:</b>  {checkString(data.Country)}</p>
          <p><b>Notes:</b> {checkString(data.Notes)}</p>
          <img src={imgUrl} />
          <p><b>Source:</b> {checkString(data.SourceName)} - {checkString(data.SourceUrl)}</p>
        </div>
      </Div>
    </Container>
  );
}

export default SingleAircraftContent;