import styled from 'styled-components';
import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Navigation from '../navigation/navigation';
import BackButton from '../utils/BackButton';
import CardContainer from '../utils/cardContainer';
import ThreeCard from '../utils/threeCard';
import FourCard from '../utils/fourCard';

function SingleBaseContent(props) {

  let { id } = useParams();

  const Container = styled.div`
    width: 100%;
    min-height: 100px;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 56px 1fr;
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    margin-top: 80px;
    @media (max-width:600px){
      margin-top:136px;
    }
    
  `;
  const Div = styled.div`
    width: 100%;
    margin-top: 52px;
    p{
      font-weight:400;
      @media (max-width:600px){
        font-size:14px;
      }
    }
   
`;

  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  let url = `${process.env.REACT_APP_API_BASE_URL}/api/targets/${id}`;

  useEffect(() => {
    setIsLoading(true);
    fetch(url)
      .then((res) => res.json())
      .then((data) => {
        setData(data);
      })
      .catch((err) => {
        setHasError(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  if (isLoading) {
    return <h1>Loading...</h1>;
  }

  if (hasError || !data) {
    return <p>Something went wrong!!!</p>;
  }

  function checkString(stringData) {
    if (stringData && stringData != "") {
      return stringData;
    }

    return "N/A";
  }

  const historyFrom = props.location.from || "/targets"

  return (
    <Container>
      <Navigation />
      <Div>
        <BackButton link={historyFrom} />
        <div className='description-container'>
            {data.Name ? <h1>{data.Name}</h1> : <h1>No Name Given</h1>}
            <p><strong>Type:</strong> {checkString(data.TargetType)} / {checkString(data.SubType)}</p>
            <p><strong>Location:</strong> {checkString(data.City)}, {checkString(data.Country)} - Coordinates: {checkString(data.Lat)}, {checkString(data.Lon)}</p>
            <p><strong>Description:</strong> {checkString(data.Description)}</p>
            <p><strong>Notes:</strong> {checkString(data.Notes)}</p>
            <CardContainer title="Missions">
            {data.Missions ? (data.Missions).map(mission => (
                <FourCard key={mission.Id} one={mission.Name} two={mission.Date} three={mission.Description} link={missionUrl(mission.Id)}/>
            )) : <h3>No Missions have been found for this Target</h3>
            }
            </CardContainer>
            <CardContainer title="Documents">
            {data.Documents ? (data.Documents).map(doc => (
                <ThreeCard key={doc.Id} one={doc.DocType} two={doc.Description} link={getDocUrl(doc.Id, doc.DocType)}/>
            )) : <h3>No Documents have been found for this Target</h3>
            }
            </CardContainer>
            </div>
      </Div>
    </Container>
  );
}

export default SingleBaseContent;

const missionUrl = (id) => {
    return `/missions/${id}`;
};

const getDocUrl = (id, type) => {
    switch(type) {
        case 'map':
            return `/phots/${id}`;
            break;
        case 'photo':
          return `/photos/${id}`;
            break;
        case 'chart':
          return `/charts/${id}`;
            break;
        default:
          return '';
      }

};