import styled from 'styled-components'

function DocumentsListItem(props) {
    
  const Container = styled.div`
    width: 90%;
    margin: 20px auto;
    padding: 10px;
    background: #22333B;
  `;

  const Pdate = styled.p`
    color: #FFF;
    margin: 0;
    width: 25%;
    text-align: center;
  `;

  const Pdetails = styled.p`
  color: #FFF;
  margin: 0;
  width: 75%;
  text-align: center;
  @media(max-width:600px){
    margin-bottom: 10px;
  }
`;
  
  const Div = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    background: transparent;
    justify-content: center;
    width: 100%;
    min-height: 100px;
    position: relative;
    opacity: 90%;       
    @media(max-width:600px){
      flex-direction: column;
    }
  `;

  function checkString(stringData) {
    if (stringData && stringData != "") {
      return stringData;
    }

    return "N/A";
  }
    
  return (
    <Container>
        <Div>
            <Pdate><strong>Type:</strong> {checkString(props.docType)} / {checkString(props.subType)}</Pdate>
            <Pdetails>{props.details}</Pdetails>
        </Div>
    </Container>
  );
}
    
export default DocumentsListItem;