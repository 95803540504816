/* eslint-disable array-callback-return */
import styled from 'styled-components'
import { useState, useEffect } from 'react';
// import FilterCheckBox from '../filterCheckBox';
import CheckboxTree from "react-checkbox-tree";
import "react-checkbox-tree/lib/react-checkbox-tree.css";

import {
  MdCheckBox,
  MdCheckBoxOutlineBlank,
  MdChevronRight,
  MdKeyboardArrowDown,
  MdAddBox,
  MdIndeterminateCheckBox,
  MdFolder,
  MdFolderOpen,
  MdInsertDriveFile
} from "react-icons/md";

function  FilterListBases(props) {

  const [checked, setChecked] = useState([]);
  const [expanded, setExpanded ] = useState([]);

  function updateQuery(filters, nodes) {

    let filterList = {countries: [], counties: []};
    
    if (filters && filters.length > 0) {
      nodes.forEach(node => {
        let label = node.label;
        if (filters.includes(label)){
          filterList.countries.push(label);
        }
        if (node.children) {
          node.children.forEach(child => {
            if (filters.includes(child.value)){
              filterList.counties.push(child.value);
            }
          })
        }
      })
    }

    setChecked(filters);
    props.isFiltered(filterList);
  }
 
    const Container = styled.div`
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
    `;
  
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [hasError, setHasError] = useState(false);
    let url = `${process.env.REACT_APP_API_BASE_URL}/api/filters/bases`;
  
    if (window.location.search) {
      url = url + window.location.search;
    } 
  
    useEffect(() => {
      setIsLoading(true);
      fetch(url)
        .then((res) => res.json())
        .then((data) => {
          setData(data);
        })
        .catch((err) => {
          setHasError(err);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }, [url]);
  
    if (isLoading) {
      return <h1>Loading...</h1>;
    }
  
    if (hasError) {
      return <p>Something went wrong!!!</p>;
    }

    let FranceArray = [];
    let UKArray = [];
    let USAArray = [];
    
    data.France.map((item) => {
      if(item) {
        FranceArray.push({ value: item, label: item })
      }
    });

    data.UK.map((item) => {
      if(item) {
        UKArray.push({ value: item, label: item })
      }
    });

    data.USA.map((item) => {
      if(item) {
        USAArray.push({ value: item, label: item })
      }
    });

    const nodes = [
      {
        value: "Belgium",
        label: "Belgium",
      },     
      {
        value: "France",
        label: "France",
        children: FranceArray,
        className: "France"
      },
      {
        value: "French Morocco",
        label: "French Morocco"
      },
      {
        value: "Germany",
        label: "Germany",
      },
      {
        value: "UK",
        label: "UK",
        children: UKArray,
        className: "UK"
      },
      {
        value: "USA",
        label: "USA",
        children: USAArray,       
        className: "USA"

      }
    ];
    
    const icons = {
      check: <MdCheckBox className="rct-icon rct-icon-check" />,
      uncheck: <MdCheckBoxOutlineBlank className="rct-icon rct-icon-uncheck" />,
      halfCheck: (
        <MdIndeterminateCheckBox className="rct-icon rct-icon-half-check" />
      ),
      expandClose: (
        <MdChevronRight className="rct-icon rct-icon-expand-close" />
      ),
      expandOpen: (
        <MdKeyboardArrowDown className="rct-icon rct-icon-expand-open" />
      ),
      expandAll: <MdAddBox className="rct-icon rct-icon-expand-all" />,
      collapseAll: (
        <MdIndeterminateCheckBox className="rct-icon rct-icon-collapse-all" />
      ),
      parentClose: <MdFolder className="rct-icon rct-icon-parent-close" />,
      parentOpen: <MdFolderOpen className="rct-icon rct-icon-parent-open" />,
      leaf: <MdInsertDriveFile className="rct-icon rct-icon-leaf-close" />
    };
   
    return (
      <Container className='category-list'>
        <CheckboxTree
        nodes={nodes}
        checked={checked}
        expanded={expanded}
        onCheck={checked => updateQuery( checked, nodes )}
        onExpand={expanded => setExpanded(expanded)}
        icons={icons}
      />
     </Container>
      // <Container className='category-list'>
      //       <h3>Location</h3>
      //       <FilterCheckBox  filterClass="country" onChange={updateQuery} opt="Belgium" />
      //       <FilterCheckBox id="txt" filterClass="country" onChange={updateQuery} opt="France" />
      //       <div className="indented">
      //       {(data.France).map(fr => (
      //           <FilterCheckBox filterClass="county" onChange={updateQuery} opt={fr} />
      //       ))}
      //       </div>
      //       <FilterCheckBox filterClass="country" onChange={updateQuery} opt="French Morocco" />
      //       <FilterCheckBox filterClass="country" onChange={updateQuery} opt="Germany" />
      //       <FilterCheckBox filterClass="country" onChange={updateQuery} opt="UK" />
      //       <div className="indented">
      //       {(data.UK).map(uk => (
      //           <FilterCheckBox filterClass="county" onChange={updateQuery} className="indented" opt={uk} />
      //       ))}
      //       </div>
      //       <FilterCheckBox filterClass="country" onChange={updateQuery} opt="UK" />
      //       <div className="indented">
      //       {(data.USA).map(us => (
      //           <FilterCheckBox filterClass="county" onChange={updateQuery} className="indented" opt={us} />
      //       ))}
      //       </div>
           
      // </Container>
      
    );
    
  }
 
  export default FilterListBases;