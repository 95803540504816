import styled from 'styled-components'

function CardContainer(props) {

    const Container = styled.div`
      min-height: 100px;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    `;
    

    const Div = styled.div`
      min-height: 100px;
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
      align-items: center;
      justify-content: space-evenly;
    `;
  
    return (
      <Container>
          <h1>{props.title}</h1>
          <Div>
            {props.children}      
          </Div>
      </Container>
    );
  }
    
  export default CardContainer;