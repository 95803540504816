import styled from 'styled-components'
import { useState, useEffect } from 'react';
// import FilterCheckBox from '../filterCheckBox';

import CheckboxTree from "react-checkbox-tree";
import "react-checkbox-tree/lib/react-checkbox-tree.css";

import {
  MdCheckBox,
  MdCheckBoxOutlineBlank,
  MdChevronRight,
  MdKeyboardArrowDown,
  MdAddBox,
  MdIndeterminateCheckBox,
  MdFolder,
  MdFolderOpen,
  MdInsertDriveFile
} from "react-icons/md";

function FilterListPhotos(props) {

  function updateQuery(filters) {
    setChecked(filters);
    props.isFiltered(filters);
  }

  const [checked, setChecked] = useState([]);
  const [expanded, setExpanded] = useState([]);


  const Container = styled.div`
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
    `;

  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [hasError, setHasError] = useState(false);
  let url = `${process.env.REACT_APP_API_BASE_URL}/api/filters/photos`;

  if (window.location.search) {
    url = url + window.location.search;
  }

  useEffect(() => {
    setIsLoading(true);
    fetch(url)
      .then((res) => res.json())
      .then((data) => {
        setData(data);
      })
      .catch((err) => {
        setHasError(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [url]);

  if (isLoading) {
    return <h1>Loading...</h1>;
  }

  if (hasError) {
    return <p>Something went wrong!!!</p>;
  }

  let locationArray = [];

  data.countries.map((item) => {
    if (item) {
      locationArray.push({ value: item, label: item })
    }
  });

  const nodes = [
    {
      value: data.countries,
      label: "Location",
      children: locationArray,
      className: "location"
    },
  ];

  const icons = {
    check: <MdCheckBox className="rct-icon rct-icon-check" />,
    uncheck: <MdCheckBoxOutlineBlank className="rct-icon rct-icon-uncheck" />,
    halfCheck: (
      <MdIndeterminateCheckBox className="rct-icon rct-icon-half-check" />
    ),
    expandClose: (
      <MdChevronRight className="rct-icon rct-icon-expand-close" />
    ),
    expandOpen: (
      <MdKeyboardArrowDown className="rct-icon rct-icon-expand-open" />
    ),
    expandAll: <MdAddBox className="rct-icon rct-icon-expand-all" />,
    collapseAll: (
      <MdIndeterminateCheckBox className="rct-icon rct-icon-collapse-all" />
    ),
    parentClose: <MdFolder className="rct-icon rct-icon-parent-close" />,
    parentOpen: <MdFolderOpen className="rct-icon rct-icon-parent-open" />,
    leaf: <MdInsertDriveFile className="rct-icon rct-icon-leaf-close" />
  };



  return (
    <Container className='category-list'>
      <CheckboxTree
        nodes={nodes}
        checked={checked}
        expanded={expanded}
        onCheck={checked => updateQuery(checked)}
        onExpand={expanded => setExpanded(expanded)}
        icons={icons}
      />

      {/* <h3>Location</h3>
            {(data.countries).map((c, index) => (
                <FilterCheckBox key={index} filterClass="locations" opt={c} />
            ))} */}
    </Container>
  );
}

export default FilterListPhotos;