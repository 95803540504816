import styled from 'styled-components';
import Navigation from '../../navigation/navigation';
import SearchContent from './searchContent';

const Container = styled.div`

`;

const NestedContainer = styled.div`
`;

function SearchPage() {
  return (
    <Container>

      <NestedContainer>
        <Navigation />
        <SearchContent />
      </NestedContainer>
    </Container>
  );
}

export default SearchPage;