import styled from 'styled-components';
import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Navigation from '../navigation/navigation';
import BackButton from '../utils/BackButton';
import AccidentListItem from './items/accidentListItem';
import AircraftListItem from './items/aircraftListItem';
import MissionsListItem from './items/missionsListItem';
import AirfieldListItem from './items/airfieldsListItem';
import DocumentsListItem from './items/documentsListItem';
import { Tab, Tabs, TabList} from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { TabPanel } from 'react-tabs';

function SingleGroupContent(props) {

  let { id } = useParams();

  const Container = styled.div`
    width: 100%;    
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 56px 1fr;
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    margin-top: 80px;
    @media (max-width:600px){
      margin-top:136px;
    }
  `;
  const Div = styled.div`
  width: 100%;
  margin-top: 52px;
  p{
      font-weight:400;     
      text-align:center;  
      @media (max-width:600px){
        font-size:14px;
      }    
    }
    h3{
      text-align: center;
    }
  `;
  const P = styled.div`
    width: 100%;
    margin: auto;
    max-width: 1200px; 
  `;

  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  let url = `${process.env.REACT_APP_API_BASE_URL}/api/groups/${id}`;

  useEffect(() => {
    setIsLoading(true);
    fetch(url)
      .then((res) => res.json())
      .then((data) => {
        setData(data);
      })
      .catch((err) => {
        setHasError(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  if (isLoading) {
    return <h1>Loading...</h1>;
  }

  if (hasError || !data) {
    return <p>Something went wrong!!!</p>;
  }

  function checkString(stringData) {
    if (stringData && stringData != "" && stringData != " ") {
      return stringData;
    }

    return "N/A";
  }

  const historyFrom = props.location.from || "/groups";

  let areaCounts = {};

  if (data) {

    areaCounts.accidents = 0;
    areaCounts.aircraft = 0;
    areaCounts.missions = 0;
    areaCounts.airfields = 0;
    areaCounts.documents = 0;
  }

  console.log(areaCounts);

  return (
    <Container>
      <Navigation />
      <Div>
        <BackButton link={historyFrom} />
        <h1><strong>Name:</strong> {checkString(data.AcLongName)}</h1>
        <p><strong>Type:</strong> {checkString(data.AcAircraftType)} / <strong>Letter</strong> {checkString(data.AcLetter)}</p>
        <p><strong>Location:</strong> {checkString(data.Country)}</p>
        <p><strong>Notes:</strong> {checkString(data.AcNotes)}</p>         
        <P dangerouslySetInnerHTML={{ __html: data.AcLongDescription }} />
        <p><strong>Wing Name:</strong> {checkString(data.WingLongName)}</p>
        <p><strong>Air Division:</strong> {checkString(data.AirDivLongName)} / <strong>Symbol</strong> {checkString(data.AirDivSymbol)}</p>

        <h3>Squadrons</h3>
        { data.Squadrons ? (data.Squadrons).map(sd => (
            <p key={sd.Id}>{sd}</p>
        )) : <h3>No Squadrons have been found for this Group</h3>
        }

<Tabs>
    <TabList>
{ data.Accidents ? <Tab> Accidents ({data.Accidents.length})</Tab> : <Tab disabled>Accidents (0)</Tab> }
{ data.Aircrafts ? <Tab> Aircraft ({data.Aircrafts.length})</Tab> : <Tab disabled>Aircraft (0)</Tab> }
{ data.Airfields ? <Tab> Airfields ({data.Airfields.length})</Tab> : <Tab disabled>Airfields (0)</Tab> }
{ data.Missions ? <Tab> Missions ({data.Missions.length})</Tab> : <Tab disabled>Missions (0)</Tab> }
{ data.Documents ? <Tab> Documents ({data.Documents.length})</Tab> : <Tab disabled>Documents (0)</Tab> }
    </TabList>


<TabPanel>
        <h3>Accidents</h3>
        { data.Accidents ? (data.Accidents).map(acc => (
            <AccidentListItem key={acc.Id} date={acc.Date} details={acc.Description} />
        )) : <h3>No Accidents have been found for this Group</h3>
        } 
</TabPanel>

<TabPanel><div>
        <h3>Aircraft</h3>
        {
          data.Aircrafts ? (data.Aircrafts).map(ac => (
              <AircraftListItem key={ac.Id} ac={ac} />
          )) : <h3>No Aircraft have been found for this Group</h3>
        }</div>
        </TabPanel>
        
        <TabPanel><div>
        <h3>Airfields</h3>
        { data.Airfields ? (data.Airfields).map(af => (
            <AirfieldListItem key={af.Id} name={af.Name} arrival={af.Arrival} depart={af.Depart} />
        )) : <h3>No Airfields have been found for this Group</h3>
        }</div>
        </TabPanel>

        <TabPanel><div>
        <h3>Missions</h3>
        { data.Missions ? (data.Missions).map(ms => (
            <MissionsListItem key={ms.Id} date={ms.Date} name={ms.Name} details={ms.Description} />
        )) : <h3>No Missions have been found for this Group</h3>
        }</div>
        </TabPanel>

        <TabPanel><div>
        <h3>Documents</h3>
        { data.Documents ? (data.Documents).map(doc => (
            <DocumentsListItem key={doc.Id} docType={doc.DocType} subType={doc.SubType} details={doc.Description} />
        )) : <h3>No Documents have been found for this Group</h3>
        } </div>
        </TabPanel>
</Tabs>




      </Div>
    </Container>
  );
}

export default SingleGroupContent;