import styled from 'styled-components';
import {Link} from 'react-router-dom'

function ThreeCard(props) {

  const Container = styled.div`
    width: 175px;
    height: 300px;
    margin: 20px;
    background: #50656E;
  `;

  const P = styled.p`
    color: #FFF;
    margin: 0;
    width: 100%;
    font-size: 24px;
    text-align: center;
    font-weight: 600;
    text-transform: uppercase;
  `;
  
  const SP = styled.p`
    color: #FFF;
    margin: 10px;
    width: 100%;
    font-size: 16px;
    text-align: center;
  `;
  
  const Div = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    background: transparent;
    justify-content: center;
    width: 100%;
    height: 175px;
    position: relative;
    opacity: 90%;
  `;

  return (
    <Container>
      <Link to={props.link}>
        <Div>
          <P>{props.one}</P>
          <SP>{props.two}</SP>
        </Div>
      </Link>
    </Container>
  );
}

export default ThreeCard;