import React from 'react'
import styled from 'styled-components'
import Calendar from 'react-calendar';
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import 'react-calendar/dist/Calendar.css';

dayjs.extend(utc)

const Container = styled.div`
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    `;

function FilterListMissions(props) {
  const handleRangeChange = (values) => {
    if (values.length === 1) {
      props.isFiltered({ start: dayjs(values[0]).utc().startOf('day').format('YYYY-MM-DD HH:mm:ss'), end: dayjs(values[0]).utc().endOf('day').format('YYYY-MM-DD HH:mm:ss') })
    } else {
      props.isFiltered({ start: dayjs(values[0]).utc().startOf('day').format('YYYY-MM-DD HH:mm:ss'), end: dayjs(values[1]).utc().endOf('day').format('YYYY-MM-DD HH:mm:ss') })
    }
  }

  return (
    <Container className='category-list'>
      <Calendar selectRange allowPartialRange defaultActiveStartDate={new Date(1942, 1, 0)} calendarType="US" onChange={handleRangeChange} />
    </Container>
  );
}

export default FilterListMissions;
